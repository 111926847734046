import { createStore } from 'vuex';

import global from './modules/global';
// eslint-disable-next-line camelcase
import config_rules from './modules/config_rules';
import agencies from './modules/agencies';
// eslint-disable-next-line camelcase
import agency_types from './modules/agency_types';
import agents from './modules/agents';
import attractions from './modules/attractions';
// eslint-disable-next-line camelcase
import blog_articles from './modules/blog/articles';
// eslint-disable-next-line camelcase
import blog_authors from './modules/blog/authors';
// eslint-disable-next-line camelcase
import blog_categories from './modules/blog/categories';
import cities from './modules/cities';
import clients from './modules/clients';
import contacts from './modules/contacts';
import currencies from './modules/currencies';
import events from './modules/events';
// eslint-disable-next-line camelcase
import excursion_categories from './modules/excursion_categories';
// eslint-disable-next-line camelcase
import excursion_categories_types from './modules/excursion_categories_types';
// eslint-disable-next-line camelcase
import excursion_directions from './modules/excursion_directions';
import tours_directions from './modules/tours_directions';
// eslint-disable-next-line camelcase
import excursion_types from './modules/excursion_types';
import excursions from './modules/excursions';
import feedback from './modules/feedback';
import levels from './modules/levels';
import logs from './modules/logs';
import managers from './modules/managers';
import nationalities from './modules/nationalities';
import notifications from './modules/notifications';
import offices from './modules/offices';
import orders from './modules/orders';
import packages from './modules/packages';
import pages from './modules/pages';
import partnership from './modules/partnership';
// eslint-disable-next-line camelcase
import pc_categories from './modules/pc_categories';
// eslint-disable-next-line camelcase
import pc_items from './modules/pc_items';
// eslint-disable-next-line camelcase
import phone_country_codes from './modules/phone_country_codes';
// eslint-disable-next-line camelcase
import point_transit from './modules/point_transit';
// eslint-disable-next-line camelcase
import price_cart_types from './modules/price_cart_types';
// eslint-disable-next-line camelcase
import price_categories from './modules/price_categories';
// eslint-disable-next-line camelcase
import price_list from './modules/price_list';
import redirects from './modules/redirects';
import regions from './modules/regions';
import sections from './modules/sections';
import seo from './modules/seo';
import settings from './modules/settings';
import subscribers from './modules/subscribers';
import team from './modules/team';
// eslint-disable-next-line camelcase
import team_categories from './modules/team_categories';
import transfers from './modules/transfers';
// eslint-disable-next-line camelcase
import upload_files from './modules/upload_files';
// eslint-disable-next-line camelcase
import world_tours_attractions from './modules/world-tours/attractions';
// eslint-disable-next-line camelcase
import world_tours_categories from './modules/world-tours/categories';
// eslint-disable-next-line camelcase
import world_tours_category_types from './modules/world-tours/category-types';
// eslint-disable-next-line camelcase
import world_tours_countries from './modules/world-tours/countries';

import world_tours_cities from './modules/world-tours/cities';
// eslint-disable-next-line camelcase
import world_tours_excursions from './modules/world-tours/excursions';
// eslint-disable-next-line camelcase
import world_tours_guides from './modules/world-tours/guides';

import organisation_tours_guides from './modules/organisation-tours/guides';
// eslint-disable-next-line camelcase
import world_tours_languages from './modules/world-tours/languages';
// eslint-disable-next-line camelcase
import world_tours_movement_types from './modules/world-tours/movement-types';
// eslint-disable-next-line camelcase
import world_tours_orders from './modules/world-tours/orders';
// eslint-disable-next-line camelcase
import world_tours_price_types from './modules/world-tours/price-types';
// eslint-disable-next-line camelcase
import world_tours_requests from './modules/world-tours/requests';
// eslint-disable-next-line camelcase
import world_tours_sections from './modules/world-tours/sections';
// eslint-disable-next-line camelcase
import hotels_countries from './modules/hotels/countries';
// eslint-disable-next-line camelcase
import hotels_cities from './modules/hotels/cities';
// eslint-disable-next-line camelcase
import hotels_stars from './modules/hotels/stars';
import hotels_flights from './modules/hotels/flights';
import hotels_transfers from './modules/hotels/transfers';
import hotels_packages from './modules/hotels/packages';
// eslint-disable-next-line camelcase
import hotels_parameters from './modules/hotels/parameters';
// eslint-disable-next-line camelcase
import hotels_procedures from './modules/hotels/procedures';
// eslint-disable-next-line camelcase
import hotels_rest_types from './modules/hotels/rest-types';
// eslint-disable-next-line camelcase
import hotels_food_types from './modules/hotels/food-types';
import hotels_room_parameters from './modules/hotels/room-parameters';
// eslint-disable-next-line camelcase
import hotels_hotels from './modules/hotels/hotels';
// eslint-disable-next-line camelcase
import hotels_orders from './modules/hotels/orders';
import organisation_tours_tour_operators from "./modules/organisation-tours/tour_operators";
import organisation_tours_tour_directions from "./modules/organisation-tours/tour_directions";
import organisation_tours_tours from "./modules/organisation-tours/tours";
import organisation_tours_tour_types from './modules/organisation-tours/tour_types';
import organisation_tours_countries from './modules/organisation-tours/countries';
import organisation_tours_attractions from './modules/organisation-tours/attractions';
import organisation_tours_sections from './modules/organisation-tours/sections';
import organisation_tours_orders from './modules/organisation-tours/orders';


// import holiday_additionally_costs from './modules/holiday/additionally_costs';
// import holiday_cities from './modules/holiday/cities';
// import holiday_contraindications from './modules/holiday/contraindications';
// import holiday_countries from './modules/holiday/countries';
// import holiday_foods from './modules/holiday/foods';
// import holiday_hotels from './modules/holiday/hotels';
// import holiday_items_hotel from './modules/holiday/items_hotel';
// import holiday_readings from './modules/holiday/readings';
// import holiday_regions from './modules/holiday/regions';
// import holiday_resorts from './modules/holiday/resorts';
// import holiday_treatments from './modules/holiday/treatments';
// import holiday_types_hotel from './modules/holiday/types_hotel';

const store = createStore({
  modules: {
    global,
    // eslint-disable-next-line camelcase
    config_rules,
    agencies,
    // eslint-disable-next-line camelcase
    agency_types,
    agents,
    attractions,
    // eslint-disable-next-line camelcase
    blog_articles,
    // eslint-disable-next-line camelcase
    blog_authors,
    // eslint-disable-next-line camelcase
    blog_categories,
    cities,
    clients,
    contacts,
    currencies,
    events,
    // eslint-disable-next-line camelcase
    excursion_categories,
    // eslint-disable-next-line camelcase
    excursion_categories_types,
    // eslint-disable-next-line camelcase
    excursion_directions,
    tours_directions,
    // eslint-disable-next-line camelcase
    excursion_types,
    excursions,
    feedback,
    levels,
    logs,
    managers,
    nationalities,
    notifications,
    offices,
    orders,
    packages,
    pages,
    partnership,
    // eslint-disable-next-line camelcase
    pc_categories,
    // eslint-disable-next-line camelcase
    pc_items,
    // eslint-disable-next-line camelcase
    phone_country_codes,
    // eslint-disable-next-line camelcase
    point_transit,
    // eslint-disable-next-line camelcase
    price_cart_types,
    // eslint-disable-next-line camelcase
    price_categories,
    // eslint-disable-next-line camelcase
    price_list,
    redirects,
    regions,
    sections,
    seo,
    settings,
    subscribers,
    team,
    // eslint-disable-next-line camelcase
    team_categories,
    transfers,
    // eslint-disable-next-line camelcase
    upload_files,
    // eslint-disable-next-line camelcase
    world_tours_attractions,
    // eslint-disable-next-line camelcase
    world_tours_categories,
    // eslint-disable-next-line camelcase
    world_tours_category_types,
    // eslint-disable-next-line camelcase
    world_tours_countries,

    world_tours_cities,
    // eslint-disable-next-line camelcase
    world_tours_excursions,
    // eslint-disable-next-line camelcase
    world_tours_guides,

    organisation_tours_guides,
    // eslint-disable-next-line camelcase
    world_tours_languages,
    // eslint-disable-next-line camelcase
    world_tours_movement_types,
    // eslint-disable-next-line camelcase
    world_tours_orders,
    // eslint-disable-next-line camelcase
    world_tours_price_types,
    // eslint-disable-next-line camelcase
    world_tours_requests,
    // eslint-disable-next-line camelcase
    world_tours_sections,
    // eslint-disable-next-line camelcase
    hotels_countries,
    // eslint-disable-next-line camelcase
    hotels_cities,
    // eslint-disable-next-line camelcase
    hotels_stars,
    hotels_flights,
    hotels_transfers,
    hotels_packages,
    // eslint-disable-next-line camelcase
    hotels_parameters,
    // eslint-disable-next-line camelcase
    hotels_procedures,
    // eslint-disable-next-line camelcase
    hotels_rest_types,
    // eslint-disable-next-line camelcase
    hotels_food_types,
    hotels_room_parameters,
    // eslint-disable-next-line camelcase
    hotels_hotels,
    // eslint-disable-next-line camelcase
    hotels_orders,
    // holiday_additionally_costs,
    // holiday_cities,
    // holiday_contraindications,
    // holiday_countries,
    // holiday_foods,
    // holiday_hotels,
    // holiday_items_hotel,
    // holiday_readings,
    // holiday_regions,
    // holiday_resorts,
    // holiday_treatments,
    // holiday_types_hotel,
    organisation_tours_tour_operators,
    organisation_tours_tour_directions,
    organisation_tours_tours,
    organisation_tours_tour_types,
    organisation_tours_countries,
    organisation_tours_attractions,
    organisation_tours_sections,
    organisation_tours_orders
  },
});

export default store;
